<template>
  <div class="distributor">
    <!-- 头部 -->
    <div class="head">
      <van-cell :border="false" title-class="nickname van-ellipsis">
        <template #icon>
          <van-image
            round
            width="60"
            height="60"
            :src="isLogin && userinfo.avatar ? userinfo.avatar : avatar"
            lazy-load
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
        <!-- 已经登陆显示内容 -->
        <template #title>
          <div
            v-show="isLogin"
            style="overflow: hidden;display: flex;-webkit-box-align: center; -webkit-align-items: center; align-items: center;"
          >
            <span
              class="nickname_text van-ellipsis"
            >{{ userinfo.nickname ? userinfo.nickname : nickname }}</span>
            <van-tag
              round
              color="#FED1A7"
              text-color="#754A20"
            >{{userinfo.levelname?userinfo.levelname:'普通会员'}}</van-tag>
          </div>
          <div v-show="isLogin && userinfo.agent">
            <span style="font-size: 12px; line-height: 20px; font-weight: normal;">{{userinfo.agent && userinfo.agent.nickname ?  "推荐人：" + userinfo.agent.nickname : ""}}</span>
          </div>
          <van-cell v-show="!isLogin" title="登陆/注册" is-link @click="lijidenglu"></van-cell>
        </template>

        <template #label v-if="isLogin">
          <van-cell v-show="isLogin && userinfo.agent_code ? true : false" @click="agentCodeCopy">
            <template #title>
              邀请码：{{ isLogin && userinfo.agent_code ? userinfo.agent_code : "" }}
              <van-tag plain>复制</van-tag>
            </template>
          </van-cell>
        </template>
        <template #right-icon v-if="isLogin">
          <van-icon @click="setMessage" class="right_icon" name="chat-o"></van-icon>
          <van-icon @click="settings" class="right_icon" name="setting-o"></van-icon>
        </template>
      </van-cell>
      <div class="shengji" v-if="false">
        <van-cell :border="false">
          <template #title>我要开店</template>
          <template #default>开通联盟店铺，轻松赚钱</template>
          <template #right-icon>
            <van-tag @click="mshegnji" round>立即开店</van-tag>
          </template>
        </van-cell>
      </div>
    </div>
    <!-- 菜单 -->
    <div class="menu">
      <van-grid :column-num="2">
        <van-grid-item text="累计佣金" @click="income(1)">
          <template #icon>
            <strong>{{commissionfinish}}</strong>
          </template>
        </van-grid-item>
        <van-grid-item text="推广订单" @click="orderList(0)">
          <template #icon>
            <strong>{{ordercount}}</strong>
          </template>
        </van-grid-item>
        <van-grid-item @click="income(0)"> <!--  @click="commissionTixian" -->
          <template #text>
            <van-cell center :border="false" title="可提现佣金" is-link />
          </template>
          <template #icon>
            <strong>{{ordercommission}}</strong>
          </template>
        </van-grid-item>
        <van-grid-item text="我的邀请" @click="orderList(1)">
          <template #icon>
            <strong>{{downcount}}</strong>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 四个菜单 -->
    <div class="option_list">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-if="false" @click="Commissionbtnlist(1)" icon="send-gift-o" text="素材中心" />
        <van-grid-item v-if="false" @click="Commissionbtnlist(2)" icon="records" text="大咖学院" />
        <van-grid-item @click="Commissionbtnlist(3)" icon="qr" text="推广码" />
        <van-grid-item v-if="false" @click="Commissionbtnlist(4)" icon="comment-o" text="通知消息" />
        <van-grid-item v-if="false" @click="Commissionbtnlist(5)" icon="service-o" text="联系客服" />
      </van-grid>
    </div>

    <!-- 绑定手机号弹框 -->
    <van-dialog
      v-model="loginshow"
      title="绑定手机号"
      show-cancel-button
      width="85%"
      @confirm="onLogin"
    >
      <div class="form">
        <van-field
          ref="tel"
          v-model="tel"
          type="tel"
          label="手机号"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="sms"
          center
          clearable
          label="验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              native-type="button"
              size="mini"
              :disabled="btnDisabled"
              type="danger"
              @click="forgetpwd"
            >
              <van-count-down v-show="!showTxt" :time="time" @finish="finish" ref="daojishi">
                <template v-slot="timeData">
                  <span class="seconds">{{ timeData.seconds }}后重新发送</span>
                </template>
              </van-count-down>
              <span v-show="showTxt">发送验证码</span>
            </van-button>
          </template>
        </van-field>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "Distributor",
  data() {
    return {
      avatar: require("../assets/head.png"), // 默认用户头像
      nickname: "大咖会员", // 用户昵称
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      adimg: require("../assets/ad.png"), // 本地广告图片
      isLogin: localStorage.getItem("DK_ISLOGIN")
        ? true
        : false,
      userinfo: {},
      commissionfinish: 0, // 已提现金额
      applycount: 0, // 提现次数
      ordercount: 0, // 分销订单
      ordercommission: 0, // 可提现金额
      downcount: 0, // 团队人数
      upagent: [], // 上级会员
      monthcommission: 0, // 本月佣金
      iswx: false, // 是否在微信中
      loginshow: false,
      btnDisabled: true,
      timeData: {
        seconds: 0
      },
      showTxt: true,
      time: -1,
      tel: "",
      sms: "",
      code: "",
      shareid: 0,
      merchid: 0,
    };
  },
  mounted() {
    
    // 用户授权后获取code值
    this.code = this.$route.query.code ? this.$route.query.code : null;
    if (this.code) {
      this.getwxlog(this.code);
      return false;
    }
    if (window.isWeixin()) {
      this.iswx = true;
    }
    const ISLOGIN = localStorage.getItem("DK_ISLOGIN");
    const OPENID = localStorage.getItem("DK_OPENID");
    /* 是否在未登录状态下点击购买按钮如果点击了跳转到确认支付页面获取进行登录或注册 */ 
    if (localStorage.getItem("Showlogin") && this.iswx) {
      this.loginshow = true;
      localStorage.removeItem("Showlogin");
    }
  },
  activated() {
    this.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (window.isWeixin()) {
      this.iswx = true;
    }
    
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "分销中心");
    this.init();
  },
  methods: {
    init() {
      console.log("分销中心");
      this.getmemberinfo(this.merchid);
      this.getcommission();
    },
    validator(val) {
      return /1\d{10}/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },
    // 获取用户微信信息
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_OPENID", response.data); // 获得用户的openid
            /* 保存openID */
            _that.saveOpenid();
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    /* 判断是否在微信中打开，如在微信中打开获取openid或用户信息 */
    getwxinfo() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      if (!window.isWeixin()) {
        openid = true; // 在H5页面中打开
        this.iswx = false; 
      }

      /* 在微信中打开页面 */
      if (!openid) { 
        this.iswx = true; 
        const wxAuthUrl2 = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl2; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },
    /* 重新刷新页面 */
    reloapage() {
      let _that = null;
      _that = this;
      _that.$router.replace({ name: "Distributor", query: {shareid: _that.shareid, merchid: _that.merchid } });// 成功后重定向，需要去除code参数，防止刷新报错(localStorage['DK_OPENID']控制是否刷新code)
    },
    /* 如果用户登录中未获的用户openID 保存用户openID */
    saveOpenid() {
      let _that = null;
      _that = this;
      let openid = "";
      openid = localStorage.getItem("DK_OPENID");
      if (openid) {
        //查询openID是否保存  
       _that.$axios
        .post(
          _that.$store.state.domain + "web/user/getmidByOpenid",
          _that.$qs.stringify({
            openid: openid 
          })
        )
        .then(res => {
          console.log(res, "getmidByOpenid");
          if (res.data.code == 100000) {
            localStorage.setItem("DK_MEMBERID", res.data.data);
            // 查询会员信息
            _that.$axios
              .post(
                _that.$store.state.domain + "web/user/get_info_byMid",
                _that.$qs.stringify({
                  id: res.data.data 
                })
              )
              .then(response => {
                if (response.data.code == 100000) {
                  localStorage.setItem("DK_UID",response.data.data);
                  localStorage.setItem("DK_ISLOGIN",true);
                  _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                } else {
                  _that.$axios
                    .post(
                      _that.$store.state.domain + "web/wetch/wx_getinfo",
                      _that.$qs.stringify({
                        openid: openid
                      })
                    )
                    .then(res => {
                      if (res.data.openid && res.data.openid == openid) {
                        localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data)); // 获得用户信息
                        localStorage.setItem("Showlogin", true);
                        _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                      } else {
                        _that.$axios
                          .post(
                            _that.$store.state.domain + "web/wetch/wx_getNewInfo",
                            _that.$qs.stringify({
                              openid: openid
                            })
                          )
                          .then(res => {
                            if (res.data.openid == openid) {
                              localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data)); // 获得用户信息
                              localStorage.setItem("Showlogin", true);
                              _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                            }
                          })
                          .catch(err => {
                            console.log(err);
                          })
                      }    
                    })
                    .catch(err => {
                      console.log(err);
                    })
                }
              })
              .catch(error => {
                console.log(error);
              })
          }else {
            // 根据openID查询会员信息
            _that.$axios
              .post(
                _that.$store.state.domain + "web/user/userInfoByOpenid",
                _that.$qs.stringify({
                  openid: openid 
                })
              )
              .then(response => {
                if (response.data.code == 100000) {
                  localStorage.setItem("DK_UID",response.data.data);
                  localStorage.setItem("DK_ISLOGIN",true);
                  // 绑定openID和mid
                  _that.$axios
                    .post(
                      _that.$store.state.domain + "web/pay/setopenid",
                      _that.$qs.stringify({
                        openid: openid,
                        mid: response.data.user.id
                      })
                    )
                    .then(response => {
                      if (response.data.code == 100000) {
                        console.log(response.data);
                        _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                      }
                    })
                }else {
                  _that.$axios
                    .post(
                      _that.$store.state.domain + "web/wetch/wx_getinfo",
                      _that.$qs.stringify({
                        openid: openid
                      })
                    )
                    .then(res => {
                      console.log(res.data);
                      if (res.data.openid && res.data.openid == openid) {
                        localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data)); // 获得用户信息
                        localStorage.setItem("Showlogin", true);
                        _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                      }else{
                        _that.$axios
                          .post(
                            _that.$store.state.domain + "web/wetch/wx_getNewInfo",
                            _that.$qs.stringify({
                              openid: openid
                            })
                          )
                          .then(res => {
                            if (res.data.openid == openid) {
                              localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data)); // 获得用户信息
                              localStorage.setItem("Showlogin", true);
                              _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                            }
                          })
                          .catch(err => {
                            console.log(err);
                          })
                      } 
                    })
                    .catch(err => {
                      console.log(err);
                    })
                }
              })
              .catch(error => {
                console.log(error);
              })
          }
        })
        .catch(error => {
          console.log(error);
        })
      }
    },

    forgetpwd() {
      if (this.tel && this.validator(this.tel)) {
        this.$axios.post(
          this.$store.state.domain + "web/user/send_sms",
          this.$qs.stringify({
            tel: this.tel,
            type: 'login'
          })
        )
        this.$refs.daojishi.reset();
        this.time = 60 * 1000;
        this.btnDisabled = true;
        this.showTxt = false;
      }else{
        this.$refs.tel.focus();
      }
    },
    // 倒计时完成后执行
    finish() {
      this.btnDisabled = false;
      this.showTxt = true;
    },

    // 登陆or注册
    onLogin() {
      let _that = null;
      _that = this;
      _that.$axios.post(
        _that.$store.state.domain + "web/user/login",
        _that.$qs.stringify({
          tel: _that.tel,
          code: _that.sms,
          codetype: 2,
          wxinfo: localStorage.getItem("DK_WXINFO"),
          shareid: _that.shareid,
          openid: localStorage.getItem("DK_OPENID"),
          memberid: localStorage.getItem("DK_MEMBERID")
        })
      ).then(function(response){
        if (response.data && response.data.code == 100000) {
          localStorage.setItem("DK_UID",response.data.data)
          localStorage.setItem("DK_ISLOGIN",true)
          localStorage.setItem("DK_MEMBERID", response.data.user.id)
          _that.$notify({
            type:'success',
            message: response.data.msg?response.data.msg:'登陆成功'
          })
          _that.islogin = localStorage.getItem("DK_ISLOGIN");
          localStorage.removeItem("DK_WXINFO");
          _that.openidBindUid(); // 绑定用户id
          setTimeout(() => {
            _that.reloapage();
          }, 1000);
        }else{
          _that.$notify({
            type:'warning',
            message: response.data && response.data.msg?response.data.msg:'登陆失败'
          })
        }
      }).catch(function(error){
        console.log(error);
      })
    },

    // openid 和用户id绑定 
    openidBindUid() {
      let _that = null;
      _that = this;
      const uid = localStorage.getItem("DK_MEMBERID");
      const openid = localStorage.getItem("DK_OPENID");
      if (parseInt(uid) > 0 && openid) {
        console.log(uid, openid, "setopenid");
        _that.$axios
          .post(
            _that.$store.state.domain + "web/pay/setopenid",
            _that.$qs.stringify({
              mid: parseInt(uid),
              openid: openid,
              type: "daka"
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.log(err);
          })
      }
    },

    // 获取佣金参数
    getcommission() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(_that.$store.state.domain + "web/commission/index")
        .then(function(response) {
          console.log(response);
          if (response.data.commissionfinish) {
            _that.commissionfinish = parseFloat(response.data.commissionfinish); // 已提现金额
          }
          if (response.data.applycount) {
            _that.applycount = parseInt(response.data.applycount); // 提现次数
          }
          if (response.data.ordercount) {
            _that.ordercount = parseFloat(response.data.ordercount); // 分销订单
          }
          if (response.data.ordercommission) {
            _that.ordercommission = parseFloat(response.data.ordercommission); // 可提现金额
          }
          if (response.data.downcount) {
            _that.downcount = parseInt(response.data.downcount); // 团队人数
          }
          if (response.data.upagent && response.data.upagent.length > 0) {
            _that.upagent = response.data.upagent; // 团队人数
          }
          if (response.data.monthcommission && response.data.monthcommission > 0) {
            _that.monthcommission = response.data.monthcommission; // 本月佣金
          }
          
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取用户信息
    getmemberinfo(mid) {
      let _that = null;
      _that = this;
      const toast = _that.$toast({
        type: 'loading',
        message: '加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
      })
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/getinfor",
          _that.$qs.stringify({
            merchid: mid
          })
        )
        .then(function(response) {
          console.log(response);
          toast.clear();
          _that.isLogin = localStorage.getItem("DK_ISLOGIN")
            ? localStorage.getItem("DK_ISLOGIN")
            : false;
          if (_that.isLogin && response.data) {
            _that.userinfo = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
          toast.clear();
        });
    },

    // 复制邀请码
    agentCodeCopy() {
      if (this.userinfo.agent_code) {
        let _that = null;
        _that = this;
        _that.$copyText(_that.userinfo.agent_code).then(
          function(e) {
            _that.$toast("复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
      }
    },
    // 消息列表
    setMessage() {
      console.log("消息");
      // this.$router.push({ name: "MessageList", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 设置页面
    settings() {
      if (this.isLogin) {
        this.$router.push({ name: "SetInfo", query: { shareid: this.shareid, merchid: this.merchid } });
      } else {
        this.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
      }
    },
    // 进入收益列表页
    income(num) {
      console.log(num);
      if (num == 0) {
        this.$router.push({ name: "ComissionTixian", query: { shareid: this.shareid, merchid: this.merchid } })
        // this.$router.push({ name: "Income", query: { status: num, shareid: this.shareid, merchid: this.merchid } });
      }else if(num == 1) {
        console.log("暂时不跳转");
      }
    },
    // 我的团队页面
    orderList(num) {
      if (num == 1) { // 我的团队
        this.$router.push({ name: "MyTeam", query: { shareid: this.shareid, merchid: this.merchid } });
      }else{
        this.$router.push({ name: "TeamOrder", query: { shareid: this.shareid, merchid: this.merchid } });
      }
    },

    // 马上升级
    mshegnji() {
      let _that = null;
      _that = this;
      if (!this.islogin) {
        this.$notify({
          type: "warning",
          message: "请登录后再申请"
        })
        if (!this.iswx) {
          setTimeout(() => {
            _that.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
          }, 1000);
        }else {
          // 微信中
          const OPENID = localStorage.getItem("DK_OPENID");
          const WXINFO = localStorage.getItem("DK_WXINFO");
          if(!OPENID) {
            _that.getwxinfo(); // 在微信中打开获取openid或用户信息
          }else if(WXINFO){
            this.loginshow = true;
          }
        }
      }else {
        if (this.userinfo.level1_white == 1) {
          if (this.userinfo.merch_reg && this.userinfo.merch_reg.lianmeng_id) {
            if (this.userinfo.merch_reg.lianmeng_status == 0) {
              this.$toast("您已经申请联盟店，等待客服审核中");
              return false;
            }
          }
          // 已经登陆直接进入申请页面
          this.$router.push({ name: "FenxiaoApply1", query: { shareid: this.shareid, merchid: this.merchid } });
        }else {
          this.$toast("等级不符合要求");
        }
      }
    },

    // 登陆
    lijidenglu(){
      let _that = null;
      _that = this;
      if (!this.islogin) {
        if (!this.iswx) {
          setTimeout(() => {
            _that.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
          }, 1000);
        }else {
          // 微信中
          const OPENID = localStorage.getItem("DK_OPENID");
          const WXINFO = localStorage.getItem("DK_WXINFO");
          if(!OPENID) {
            _that.getwxinfo(); // 在微信中打开获取openid或用户信息
          }else if(WXINFO){
            this.loginshow = true;
          }
        }
      }
    },
    // 五个能能按钮
    Commissionbtnlist(num) {
      console.log(this.islogin);
      if (num == 1) {
        console.log("素材中心");
        this.$router.push({ name: "Material", query: { shareid: this.shareid, merchid: this.merchid } })
      } else if (num == 2) {
        console.log("大咖学院");
      } else if (num == 3) {
        console.log("推广码");
        this.$router.push({ name: "PromoteCode", query: { shareid: this.shareid, merchid: this.merchid } })
      } else if (num == 4) {
        console.log("通知消息");
      } else if (num == 5) {
        console.log("联系客服");
      }
    }
  }
};
</script>
<style lang="less">
.distributor {
  overflow-x: hidden;
  position: relative;
  background: #ededed;
  min-height: 100%;
  // 头部
  .head {
    background-color: #ed2846;
    .van-cell {
      padding: 10px;
      background-color: #ed2846;
      .nickname {
        flex: 1;
        padding: 0 10px;
        position: relative;
        text-align: left;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        .nickname_text {
          display: inline-block;
          vertical-align: middle;
        }
        .van-cell {
          padding: 10px 0;

          .van-cell__title {
            color: #fff;
            font-weight: normal;
          }
          .van-icon {
            color: #fff;
          }
        }
        .van-tag {
          font-size: 10px;
          padding: 0 3px;
          margin-left: 5px;
          vertical-align: middle;
          height: 14px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
        }
      }
      .van-cell__label {
        margin-top: -4px;
        .van-cell {
          padding: 0;
          color: #fff;
          font-size: 12px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
          .van-cell__title {
            flex: 1;
            line-height: 24px;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
            .van-tag {
              background-color: rgba(255, 0, 0, 0);
              border-color: #fff;
              color: #fff;
              margin-left: 3px;
              padding: 0 4px;
              font-size: 10px;
            }
          }
        }
      }
      .right_icon {
        color: #fff;
        font-size: 24px;
        margin-right: 20px;
        &:last-of-type {
          margin-right: 10px;
        }
      }
    }
    .shengji {
      padding: 0 10px;
      overflow: hidden;
      .van-cell {
        padding: 10px;
        background-color: #efddb6;
        border-radius: 10px 10px 0 0;
        .van-cell__title {
          color: #944604;
          font-size: 14px;
          font-weight: bold;
        }
        .van-cell__value {
          flex: 1;
          padding: 0 10px;
          text-align: left;
          color: #914602;
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .van-tag {
          padding: 0 10px;
          background-color: #fff;
          font-size: 12px;
          color: #944604;
        }
      }
    }
  }
  // 菜单
  .menu {
    padding: 0 10px 10px;
    overflow: hidden;
    .van-grid {
      overflow: hidden;
      &::after {
        border: 0;
      }
      .van-grid-item__content {
        padding: 16px;
        text-align: left;
        -webkit-box-align: flex-start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        .van-grid-item__icon-wrapper {
          line-height: 40px;
        }
        .van-cell {
          padding: 0;
          .van-cell__title {
            flex: none;
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
    .van-cell {
      border-radius: 0 0 10px 10px;
      text-align: left;
      .van-cell__title {
        flex: 1;
      }
      .van-cell__value {
        color: #ee0021;
        font-weight: bold;
      }
    }
  }
  // 横向四个菜单
  .option_list {
    padding: 0 10px 10px;
    overflow: hidden;
    .van-grid {
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      .van-grid-item {
        .van-grid-item__content {
          .van-icon {
            color: #e70e39;
          }
          .van-grid-item__text {
            color: #999;
          }
        }
      }
    }
  }

  // 绑定手机号弹框
  .form{
    overflow: hidden;
    width: 100%;
    .van-cell{
      .van-cell__title{
        width: 65px;
      }
      .van-cell__value{
        flex: 1;
      }
      .van-count-down{
        background-color: #bbb;
        padding: 1px 6px;
        .seconds{
          color: #ffffff;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
